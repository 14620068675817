<template>
  <v-app>
    <top-header v-if="showHeader" @logout="logout"/>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Layouts from 'layouts'

export default {
  data() {
    return {
      showHeader: false,
    }
  },
  components: {
    ...Layouts,
  },
};
</script>

<style lang="scss">
    @import 'styles/main';
</style>

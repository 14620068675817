<template>
    <v-row>
      <v-col>
        <TopicsList />
      </v-col>
    </v-row>
  </template>
  
  <script>
    import TopicsList from 'components/topics/TopicsList'
    import Layouts from 'layouts'
  
    export default {
      components: {
        ...Layouts,
        TopicsList
      },
      beforeRouteEnter(to, from, next) {
        next()
      },
    };
  </script>
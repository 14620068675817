<template v-show="showHeader">
  <header class="header">
    <v-row class="d-flex">
      <!-- <v-col class="v-col-1 ml-10">
        <v-img src="@/assets/logo-1.png" alt="Logo"/>
      </v-col> -->
      <v-col class="v-col-9 mt-4">
        <h1> MQTT Subscribe Topics </h1>
      </v-col>
    </v-row>
  </header>
</template>

<script>
import { mapGetters} from 'vuex'

export default {
  name: 'Header',
};
</script>

<style scoped>
.header {
    background-color: #043e84;
    color: rgb(255, 255, 255);
    padding: 10px 0;
}

</style>

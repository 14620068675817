import { createApp } from 'vue'

import axios from 'axios'
import App from 'App'
import router from 'router'
import { APP_CONFIG } from 'constants.js'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { fa } from 'vuetify/iconsets/fa'
import '@mdi/font/css/materialdesignicons.css'


const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    iconfont: 'mdi',
    aliases,
    sets: {
      mdi,
      fa
    },
  },
})

const app = createApp(App).use(vuetify)

app.config.globalProperties.$config = {
  get() {
    return APP_CONFIG
  }
}

app.use(router)

const apiClient = axios.create({
    baseURL: APP_CONFIG.api_url,
    headers: {
        'Content-Type': 'application/json'
        // 'Access-Control-Allow-Origin': "*"
    }
})

apiClient.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

app.config.globalProperties.$axios = apiClient;

app.mount('#app')

import Topics from 'pages/TopicsPage'

export default {
    path: '/topics',
    name: 'topics',
    component: Topics,
    meta: {
        guest: true,
        requiresAuth: false,
        title: 'Topics'
    }
}

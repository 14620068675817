import { createRouter, createWebHistory } from 'vue-router';
import TopicsRoute from 'router/topics'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    TopicsRoute,
    {
      path: '/', 
      redirect: '/topics',
    },
  ],
});


export default router;
<template>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <v-table class="ml-7 mt-10">
        <tbody>
          <tr class="tb-row">
            <td>
              <v-text-field
              v-model="topic.name"
              color="blue"
              label="New topic"
              variant="underlined"
              ></v-text-field>
            </td>
            <td>
              <v-btn
                @click="createTopic()"
                color="success"
                prepend-icon="mdi-plus-thick"
                variant="text"
                class="btn-action"
              > 
              <v-tooltip
                activator="parent"
                location="top"
              >Add topic</v-tooltip>
              </v-btn>
            </td>
          </tr>
          <tr class = "table-row"
            v-for="item in topics"
            :key="item.id"
          >
            <td >
              <v-input prepend-icon="mdi-text-box-multiple-outline">
                {{ item.name }}
              </v-input>
              <!-- <v-btn prepend-icon="mdi-text-box-multiple-outline" variant="plain">
                {{ item.name }}
              </v-btn> -->
            </td>
            <td>
              <v-btn 
                @click="exportCsv(item.name)" 
                color="success" 
                variant="tonal"
              >
                CSV Export
              </v-btn>
            </td>
            <td >
              <v-btn
                @click="deleteTopic(item.ID)"
                color="error"
                prepend-icon="mdi-delete"
                variant="text"
                class="btn-action"
              > 
              <v-tooltip
                activator="parent"
                location="top"
              >Delete</v-tooltip>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </template>

<script>

export default {
  data() {
    return {
      loading: false,
      topics: [],
      topic: {
        name: '',
      },
    };
  },
  methods: {
    async loadTopics() {
      this.loading = true;
      await this.$axios.get('/topics')
      .then((response) => {
        this.topics = response.data;
        this.loading = false;
      })
      .catch((err) => {
        console.error('Error loading Topics:', err);
        this.loading = false;
      });
    },
    async createTopic() {
      try {
        const formData = new FormData()
        for (const key in this.topic) {
          formData.append(key, this.topic[key])
        }
        await this.$axios.post('/topics', formData)
      } catch(error) {
        console.error('Error adding new topic:', error);
      }
      this.loadTopics()
      this.topic.name = ''
    },
    async deleteTopic(topicId) {
      try {
        await this.$axios.delete(`/topics/${topicId}`)
      } catch(error) {
        console.error('Error deleting topic:', error);
      }
      this.loadTopics()
    },
    async exportCsv(topic) {
      await this.$axios.get(`/export?topic=${topic}`)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = response.headers['x-filename'] ? response.headers['x-filename'] : `export_${topic}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch((err) => {
        console.error('Error loading Topics:', err);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadTopics()
  },
};
</script>

<style scoped>
/* >>> .v-input__details {
  grid-area: auto;
} */

>>> .btn-action .v-btn__prepend i::before {
  font-size: 24px;
}

</style>